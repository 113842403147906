body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: sans-serif;
}

@-webkit-keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
     } 
} 
@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
     } 
} 

.navbar-list {
  list-style: none;
  padding: 0;
}

.navbar-list > li {
  display: inline;
  padding: 10px;
}

.navbar-link {
  text-decoration: none;
  color: black;
  -webkit-transition: 1s;
  transition: 1s;
  padding: 10px;
}

.navbar-link:hover, .navbar-link:focus {
  background-color: lightgray;
}

.navbar-link.active {
  background-color: #a2a2a2;
}

.window {
  
}

