.navbar-list {
  list-style: none;
  padding: 0;
}

.navbar-list > li {
  display: inline;
  padding: 10px;
}

.navbar-link {
  text-decoration: none;
  color: black;
  transition: 1s;
  padding: 10px;
}

.navbar-link:hover, .navbar-link:focus {
  background-color: lightgray;
}

.navbar-link.active {
  background-color: #a2a2a2;
}
